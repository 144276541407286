import React from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { Button, cloudsAnimationWrapper } from '../elements'
import { NonceImg } from '../img'

import introHomeBG from '../../images/home/home_sky_bg.svg'
import introHomeMobileBG from '../../images/home/home_sky_mobile_bg.svg'
import introHomeCloudDesctop from '../../images/home/intro_home_cloud_desktop.svg'
import introHomeCloudMobile from '../../images/home/intro_home_cloud_mobile.svg'
import arrowBtnIcon from '../../images/arrow_btn_icon_green.svg'

const CapabilityHomeWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background-image: url(${introHomeBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
  margin-bottom: 100px;
  z-index: 10;
  @media (min-width: 768px) {
    background-position-y: -55%;
  }
  @media (max-width: 767px) {
    background-image: url(${introHomeMobileBG});
  }
  @media (max-width: 575px) {
    background-position-y: -90%;
    margin-bottom: 0;
  }
  @media (max-width: 380px) {
    background-position-y: -10%;
    background-size: auto 52.5%;
  }
`

const CapabilityHomeCol = styled(Col)`
  position: relative;
  padding-top: 15px;
  z-index: 40;

  @media (max-width: 575px) {
    padding-top: 0;
  }

  &:first-of-type {
    z-index: 60;
  }
`

const CapabilityHomeColLeft = styled(CapabilityHomeCol)`
  @media (min-width: 576px) {
    padding-right: 2%;
  }
`

const CapabilityHomeColRight = styled(CapabilityHomeCol)`
  @media (min-width: 576px) {
    padding-left: 2%;
  }
`

const IntroHomeCloudWrapper = styled.div`
  position: absolute;
  top: -3%;
  left: 80%;
  width: 79%;
  background-image: url(${introHomeCloudDesctop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: ${(props) => props.theme.colorGreen};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 34px;
  font-weight: 700;
  line-height: 1.207;
  padding-top: 79px;
  padding-left: 69px;
  padding-right: 20px;
  padding-bottom: 90px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 29px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: -5%;
    font-size: 21px;
    padding-top: 81px;
    padding-left: 62px;
    padding-right: 20px;
    padding-bottom: 90px;
  }
  @media (max-width: 767px) {
    position: static;
    background-image: url(${introHomeCloudMobile});
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: -40px;
  }
  @media (max-width: 575px) {
    width: 260px;
    font-size: 20px;
    padding-top: 25px;
    padding-left: 40px;
    margin-bottom: -60px;
  }
`

const CapabilityHomeDescWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const CapabilityHomeInnerDescWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontBNPPSans};
  font-weight: 300;
  padding-left: 8%;
  padding-right: 8%;

  @media (max-width: 575px) {
    height: 98%;
    padding-left: 0;
    padding-right: 0;
  }
  p {
    margin-bottom: 25px;

    &.no-margin {
      @media (max-width: 575px) {
        margin-bottom: 0;
      }
    }

    .header {
      display: block;
      color: #00975f;
      font-size: 34px;
      font-weight: 700;
      line-height: 1.2;
      padding-bottom: 25px;
      padding-top: 45px;

      &.header-first {
        @media (max-width: 575px) {
          padding-top: 0;
        }
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 28px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 22px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
        padding-bottom: 0;
      }
    }
  }
`

const CapabilityHomeImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 15px;
  margin-bottom: -100px;
  @media (max-width: 575px) {
    margin-bottom: -70px;
  }
  &:after {
    position: absolute;
    top: calc(100% - 100px);
    left: -8%;
    right: -8%;
    display: block;
    height: 5000px;
    background-color: ${(props) => props.theme.colorWhite};
    z-index: -10;
    @media (min-width: 768px) {
      /* content: ''; */
    }
  }
`

const CapabilityHomeImageWrapperLeft = styled(CapabilityHomeImageWrapper)`
  &:before {
    position: absolute;
    top: calc(100% - 50px);
    right: -60px;
    bottom: 0;
    display: block;
    width: 1px;
    height: 5000px;
    background-color: ${(props) => props.theme.colorGray};
    z-index: -10;
    @media (max-width: 991px) {
      right: -43px;
    }
    @media (min-width: 768px) {
      content: '';
    }
  }
`

const CapabilityHomeImageWrapperRight = styled(CapabilityHomeImageWrapper)`
  @media (min-width: 992px) {
    top: -30px;
    width: 120%;
    margin-left: -16%;
    margin-right: -8%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 110%;
    margin-left: -8%;
    margin-right: -8%;
  }
`

const CapabilityHomeButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`

export const CapabilityHomeButton = styled(Button)`
  background-color: #fff;
  color: #00975f;
  /* font-size: 18px; */
  border-color: #00975f;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 16px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 50px;
  }
  @media (max-width: 575px) {
    width: 100%;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 30px;
  }
  &:hover,
  &:focus {
    color: #00975f;
    &:after {
      right: 12px;
    }
  }
  &:after {
    width: 10px;
    height: 16px;
    background-image: url(${arrowBtnIcon});
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 8px;
      height: 14px;
    }
    @media (max-width: 575px) {
      right: 15px;
    }
  }
`

const CapabilityHomeCloudsAnimationWrapper = styled(cloudsAnimationWrapper)``

const CapabilityHome = (props) => {
  const data = useStaticQuery(graphql`
    query {
      homeChildren: file(relativePath: { eq: "home/children.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      homeTools: file(relativePath: { eq: "home/tools.png" }) {
        childImageSharp {
          fluid(maxWidth: 626) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <CapabilityHomeWrapper>
      <CapabilityHomeCloudsAnimationWrapper>
        <div className="layersWrapper">
          <div className="cloudLayer once cloudLayerOnce1" />
          <div className="cloudLayer once cloudLayerOnce2" />
          <div className="cloudLayer once cloudLayerOnce3" />
          <div className="cloudLayer once cloudLayerOnce4" />
          <div className="cloudLayer cloudLayer1" />
          <div className="cloudLayer cloudLayer2" />
          <div className="cloudLayer cloudLayer3" />
          <div className="cloudLayer cloudLayer4" />
          <div className="cloudLayer cloudLayer5" />
          <div className="cloudLayer cloudLayer6" />
          <div className="cloudLayer cloudLayer7" />
          <div className="cloudLayer cloudLayer8" />
        </div>
      </CapabilityHomeCloudsAnimationWrapper>
      <Container>
        <Row>
          <CapabilityHomeColLeft md={6}>
            <IntroHomeCloudWrapper>
              Witamy w Misji Samodzielność. Super, że tu jesteś!
            </IntroHomeCloudWrapper>
            <CapabilityHomeDescWrapper>
              <CapabilityHomeInnerDescWrapper>
                <CapabilityHomeImageWrapperLeft>
                  <NonceImg fluid={data.homeChildren.childImageSharp.fluid} />
                </CapabilityHomeImageWrapperLeft>

                <p className="no-margin">
                  <span className="header header-first">
                    Samodzielniaki
                    <br /> nie boją się wyzwań,
                  </span>

                  <span className="d-block d-sm-none">
                    Poznajcie gry edukacyjne, które pomagają dzieciom oswoić się
                    z{'\u00A0'}bankowaniem!
                  </span>

                  <span className="d-none d-sm-block">
                    mają otwarte głowy i{'\u00A0'}umieją radzić sobie w
                    {'\u00A0'}
                    zmieniającym się świecie, nawet w{'\u00A0'}świecie finansów.
                    Wierzymy, że każde dziecko może się nauczyć takiej
                    samodzielności, jeśli tylko ma{'\u00A0'}odpowiednie
                    narzędzia. Żeby ułatwić to{'\u00A0'}zadanie rodzicom i
                    {'\u00A0'}dzieciom, stworzyliśmy ciekawe gry edukacyjne,
                    które przez zabawę wprowadzają dziecko w{'\u00A0'}świat
                    finansów. Poznajcie je
                    {'\u00A0'}razem!
                  </span>
                </p>

                <CapabilityHomeButtonWrapper>
                  <CapabilityHomeButton
                    as="a"
                    href={withPrefix('/game')}
                    bg="transparent"
                    className="btn"
                    target="_blank"
                    id="home-btn-zagraj-w-samodzielnosc"
                  >
                    Zagraj w samodzielność
                  </CapabilityHomeButton>
                </CapabilityHomeButtonWrapper>
              </CapabilityHomeInnerDescWrapper>
            </CapabilityHomeDescWrapper>
          </CapabilityHomeColLeft>
          <CapabilityHomeColRight md={6}>
            <CapabilityHomeDescWrapper>
              <CapabilityHomeInnerDescWrapper>
                <CapabilityHomeImageWrapperRight>
                  <NonceImg fluid={data.homeTools.childImageSharp.fluid} />
                </CapabilityHomeImageWrapperRight>

                <p>
                  <span className="header">
                    Mamy
                    <br /> też nowoczesne
                    <br /> narzędzia,{' '}
                  </span>
                  dzięki którym dziecko może stawiać pierwsze kroki w{'\u00A0'}
                  świecie finansów pod czujnym okiem rodziców.
                </p>

                <CapabilityHomeButtonWrapper>
                  <CapabilityHomeButton
                    as={Link}
                    to="/ekwipunek-samodzielniaka"
                    bg="transparent"
                    className="btn"
                    id="home-btn-ekwipunek-samodzielniaka"
                  >
                    odkryj ekwipunek samodzielniaka
                  </CapabilityHomeButton>
                </CapabilityHomeButtonWrapper>
              </CapabilityHomeInnerDescWrapper>
            </CapabilityHomeDescWrapper>
          </CapabilityHomeColRight>
        </Row>
      </Container>
    </CapabilityHomeWrapper>
  )
}

export default CapabilityHome
