import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

const ParentAreaWrapper = styled.div`
  background-color: ${(props) => props.theme.colorWhite};
  padding-top: 35px;
  padding-bottom: 35px;
  @media (max-width: 575px) {
    ${(props) =>
      props.page === 'home' &&
      `
      padding-top: 25px;
      padding-bottom: 0;
      margin-bottom: -10px;
    `}
    ${(props) =>
      props.page === 'parent' &&
      `
      margin-top: 100px;
    `}
  }
`

const ParentAreaContainer = styled(Container)``

const ParentAreaDescWrapper = styled(Col)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.35;
  @media (max-width: 575px) {
    ${(props) =>
      props.page === 'parent' &&
      `
      order: 2;
    `}
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    color: ${(props) => props.theme.colorGreenLight};
    font-weight: 400;
    text-decoration: none;
  }
`

const ParentAreaLogosWrapper = styled(Col)`
  display: flex;
  align-items: center;
  @media (max-width: 575px) {
    ${(props) =>
      props.page === 'parent' &&
      `
      order: 1;
    `}
  }
`

const ParentAreaLogosInnerWrapper = styled.div`
  width: 100%;
  text-align: right;
  @media (max-width: 991px) {
    text-align: center;
    margin-top: 20px;
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.page === 'parent' &&
      `
      margin-top: -75px;
    `}
  }
`

const ParentAreaLogoWrapper = styled.div`
  display: inline-block;
  ${(props) =>
    props.number === 1 &&
    `
    width: 110px;
  `}
  ${(props) =>
    props.number === 2 &&
    `
    width: 110px;
    margin-left: 20px;
  `}
  ${(props) =>
    props.number === 3 &&
    `
    width: 210px;
    margin-left: 70px;
  `}
  @media (max-width: 767px) {
    ${(props) =>
      props.number === 3 &&
      `
      margin-left: 15px;
    `}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
      props.number === 3 &&
      `

    `}
  }
  @media (max-width: 575px) {
    margin-bottom: 30px;
    ${(props) =>
      props.number === 1 &&
      `
    width: 70px;
    `}
    ${(props) =>
      props.number === 2 &&
      `
    width: 70px;
    `}
    ${(props) =>
      props.number === 3 &&
      `
      width: 130px;
      margin-left: 15px;
    `}
  }
`

const ParentArea = (props) => {
  const { page } = props

  const data = useStaticQuery(graphql`
    query {
      ParentAreaLogo1: file(relativePath: { eq: "parent/logo1.png" }) {
        childImageSharp {
          fluid(maxWidth: 108, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ParentAreaLogo2: file(relativePath: { eq: "parent/logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 108, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ParentAreaLogo3: file(relativePath: { eq: "parent/logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 308, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ParentAreaWrapper page={page}>
      <ParentAreaContainer page={page}>
        <Row>
          <ParentAreaDescWrapper page={page} xs={12} lg={6}>
            <p>
              Strona{' '}
              <a href="misjasamodzielnosc.pl" title="misjasamodzielnosc.pl">
                misjasamodzielnosc.pl
              </a>{' '}
              i{'\u00A0'}jej zawartość realizują dwa z{'\u00A0'}17 Celów
              Zrównoważonego Rozwoju, jakimi są{'\u00A0'}dobra jakość edukacji,
              w{'\u00A0'}tym dostęp do{'\u00A0'}edukacji finansowej wszystkich
              dzieci oraz wyrównanie szans dzieci w{'\u00A0'}szkole, aby nie
              oceniały rówieśników przez pryzmat pieniędzy.
            </p>
          </ParentAreaDescWrapper>
          <ParentAreaLogosWrapper page={page} xs={12} lg={6}>
            <ParentAreaLogosInnerWrapper page={page}>
              <ParentAreaLogoWrapper page={page} number={1}>
                <Img
                  fluid={data.ParentAreaLogo1.childImageSharp.fluid}
                  className="parentAreaLogo"
                />
              </ParentAreaLogoWrapper>
              <ParentAreaLogoWrapper page={page} number={2}>
                <Img
                  fluid={data.ParentAreaLogo2.childImageSharp.fluid}
                  className="parentAreaLogo"
                />
              </ParentAreaLogoWrapper>
              <ParentAreaLogoWrapper page={page} number={3}>
                <Img
                  fluid={data.ParentAreaLogo3.childImageSharp.fluid}
                  className="parentAreaLogo"
                />
              </ParentAreaLogoWrapper>
            </ParentAreaLogosInnerWrapper>
          </ParentAreaLogosWrapper>
        </Row>
      </ParentAreaContainer>
    </ParentAreaWrapper>
  )
}

export default ParentArea
