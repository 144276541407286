import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { NonceImg } from '../img'

const ActionsWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 575px) {
  }
`

const ActionsContainer = styled(Container)``

const ActionsTitle = styled.h2`
  position: relative;
  background-color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontBNPPSans};
  color: ${(props) => props.theme.colorBlack};
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  padding-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 50px;
  }
  @media (max-width: 575px) {
    font-size: 34px;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    background-color: #91bd24;
    width: 110px;
    height: 4px;
    transform: translateX(-50%);
  }
`

const ActionsListWrapper = styled(Row)``

const ActionsListItem = styled(Col)`
  color: ${(props) => props.theme.colorBlack};
  text-align: center;
  padding-top: 10px;
`

const ActionsListItemImageWrapper = styled.div`
  margin-bottom: 15px;
`

const ActionsListItemTitle = styled.h3`
  position: relative;
  font-family: ${(props) => props.theme.fontBNPPSans};
  font-size: 24px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
  }
  a {
    color: ${(props) => props.theme.colorBlack}!important;
    text-decoration: none;
  }
`

const ActionsListItemDesc = styled.p`
  font-size: 16px;
  font-weight: 300;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const ActionsListItemNewWrapper = styled.div`
  font-size: 14px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 8px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const ActionsListItemNewTitle = styled.p`
  display: inline-block;
  background-color: #c20069;
  color: ${(props) => props.theme.colorWhite};
  font-weight: 400;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 5px 20px 0px rgba(129, 0, 70, 0.19);
  strong {
    font-weight: 700;
  }
`

const ActionsListItemNewSubDesc = styled.p``

const Actions = (props) => {
  const data = useStaticQuery(graphql`
    query {
      actionImage1: file(relativePath: { eq: "home/action1.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      actionImage2: file(relativePath: { eq: "home/action2.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      actionImage3: file(relativePath: { eq: "home/action3.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      actionImage4: file(relativePath: { eq: "home/action4.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ActionsWrapper id="top">
      <ActionsContainer>
        <Row>
          <Col xs={12}>
            <ActionsTitle>Nasze działania</ActionsTitle>
          </Col>
        </Row>
        <ActionsListWrapper>
          <ActionsListItem xs={12} sm={6} md={3}>
            <ActionsListItemImageWrapper>
              <Link to="/strefa-nauczyciela" title="Strefa nauczyciela">
                <NonceImg
                  fluid={data.actionImage1.childImageSharp.fluid}
                  alt="Strefa nauczyciela"
                />
              </Link>
            </ActionsListItemImageWrapper>
            <ActionsListItemTitle>
              <Link to="/strefa-nauczyciela" title="Strefa nauczyciela">
                Strefa nauczyciela
              </Link>
            </ActionsListItemTitle>
            <ActionsListItemDesc>
              Dołącz do{'\u00A0'}programu edukacyjnego i{'\u00A0'}zacznij z
              {'\u00A0'}uczniami wartościowe dyskusje!
            </ActionsListItemDesc>
          </ActionsListItem>
          <ActionsListItem xs={12} sm={6} md={3}>
            <ActionsListItemImageWrapper>
              <Link to="/strefa-dziecka" title="Strefa dziecka">
                <NonceImg
                  fluid={data.actionImage2.childImageSharp.fluid}
                  alt="Strefa dziecka"
                />
              </Link>
            </ActionsListItemImageWrapper>
            <ActionsListItemTitle>
              <Link to="/strefa-dziecka" title="Strefa dziecka">
                Strefa dziecka
              </Link>
            </ActionsListItemTitle>
            <ActionsListItemDesc>
              Gramy w{'\u00A0'}samodzielność, czyli w{'\u00A0'}gry finansowe{' '}
            </ActionsListItemDesc>
          </ActionsListItem>
          <ActionsListItem xs={12} sm={6} md={3}>
            <ActionsListItemImageWrapper>
              <Link to="/strefa-rodzica" title="Strefa rodzica">
                <NonceImg
                  fluid={data.actionImage3.childImageSharp.fluid}
                  alt="Strefa rodzica"
                />
              </Link>
            </ActionsListItemImageWrapper>
            <ActionsListItemTitle>
              <Link to="/strefa-rodzica" title="Strefa rodzica">
                Strefa rodzica
              </Link>
            </ActionsListItemTitle>
            <ActionsListItemDesc>
              Uzupełniamy wiedzę, żeby inspirować samodzielniaki
            </ActionsListItemDesc>
            <ActionsListItemNewWrapper>
              <ActionsListItemNewTitle>
                <strong>Nowość:</strong> Misja Kieszonkowe
              </ActionsListItemNewTitle>
              <ActionsListItemNewSubDesc>
                Ile? Kiedy i{'\u00A0'}czy w{'\u00A0'}ogóle?
              </ActionsListItemNewSubDesc>
            </ActionsListItemNewWrapper>
          </ActionsListItem>
          <ActionsListItem xs={12} sm={6} md={3}>
            <ActionsListItemImageWrapper>
              <Link to="/ekwipunek-samodzielniaka" title="Konto samodzielniaka">
                <NonceImg
                  fluid={data.actionImage4.childImageSharp.fluid}
                  alt="Konto samodzielniaka"
                />
              </Link>
            </ActionsListItemImageWrapper>
            <ActionsListItemTitle>
              <Link to="/ekwipunek-samodzielniaka" title="Konto samodzielniaka">
                Konto samodzielniaka
              </Link>
            </ActionsListItemTitle>
            <ActionsListItemDesc>
              Korzystamy z{'\u00A0'}bezpiecznych produktów bankowych{' '}
            </ActionsListItemDesc>
          </ActionsListItem>
        </ActionsListWrapper>
      </ActionsContainer>
    </ActionsWrapper>
  )
}

export default Actions
