import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'
import { NonceImg } from '../img'

const NeedWrapper = styled.div`
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 30px;
  @media (max-width: 575px) {
    padding-top: 80px;
    padding-bottom: 20px;
    margin-top: 0;
  }
  @media (max-width: 320px) {
    /* padding-top: 300px; */
  }
`

const NeedContainer = styled(Container)``

const NeedRowWrapper = styled(Row)``

const NeedTitle = styled.h1`
  font-family: ${(props) => props.theme.fontBNPPSans};
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 72px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 40px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 46px;
  }
  @media (max-width: 767px) {
    display: none;
    font-size: 48px;
    margin-bottom: 50px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
    margin-bottom: 30px;
  }
  span {
    font-size: 40px;
    font-weight: 400;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 33px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 30px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
`

const NeedTitleMobile = styled(NeedTitle)`
  display: none;
  text-align: center;
  @media (max-width: 767px) {
    display: block;
  }
  span {
    display: block;
  }
`

const NeedDescWrapper = styled(Col)`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontBNPPSans};
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  @media (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 767px) {
    order: 1;
    padding-top: 30px;
  }
  p {
    margin-bottom: 15px;
    @media (max-width: 767px) {
    }
    @media (max-width: 575px) {
    }
  }
`

const NeedDescInnerWrapper = styled.div``

const NeedDescLinkWrapper = styled.div`
  padding-top: 10px;
  @media (max-width: 767px) {
    text-align: center;
  }
`

const NeedDescLink = styled(ButtonLink)``

const NeedImageWrapper = styled(Col)``

const NeedImageInnerWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 6.25%;
    left: 10%;
    right: 10%;
    bottom: 6.25%;
    display: block;
    border: 12px solid ${(props) => props.theme.colorGreenLight};
    z-index: 10;
    @media (max-width: 767px) {
      bottom: 0;
      transform: translateY(-55%);
    }
    @media (max-width: 575px) {
      bottom: -15%;
      border: 8px solid ${(props) => props.theme.colorGreenLight};
      transform: translateY(-50%);
    }
  }
`

const Need = (props) => {
  const data = useStaticQuery(graphql`
    query {
      needImage: file(relativePath: { eq: "home/need.png" }) {
        childImageSharp {
          fluid(maxWidth: 636) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <NeedWrapper id="top">
      <NeedContainer>
        <Row>
          <Col xs={12}>
            <NeedTitleMobile as="p">
              <span>Świat potrzebuje</span> samodzielnych
              <br /> dzieciaków.
            </NeedTitleMobile>
          </Col>
        </Row>
        <NeedRowWrapper>
          <NeedDescWrapper xs={12} md={6}>
            <NeedDescInnerWrapper>
              <NeedTitle>
                <span>Świat potrzebuje</span> samodzielnych
                <br /> dzieciaków.
              </NeedTitle>
              <p>
                Takich, które umieją bezpiecznie obchodzić się z{'\u00A0'}
                produktami bankowymi i{'\u00A0'}mądrze gospodarować
                kieszonkowym. Właśnie po{'\u00A0'}to{'\u00A0'}prowadzimy Misję
                Samodzielność i{'\u00A0'}wraz z{'\u00A0'}nauczycielami,
                ekspertami i{'\u00A0'}
                rodzicami tworzymy ciekawe narzędzia, materiały i{'\u00A0'}
                edu-warsztaty.
              </p>
              <NeedDescLinkWrapper>
                <NeedDescLink
                  as={Link}
                  to="/strefa-rodzica/kieszonkowe"
                  className="btn"
                  title="o Misji Kieszonkowe"
                >
                  o Misji Kieszonkowe 2022
                </NeedDescLink>
              </NeedDescLinkWrapper>
            </NeedDescInnerWrapper>
          </NeedDescWrapper>
          <NeedImageWrapper xs={12} md={6}>
            <NeedImageInnerWrapper>
              <NonceImg
                fluid={data.needImage.childImageSharp.fluid}
                alt="o Misji Kieszonkowe"
              />
            </NeedImageInnerWrapper>
          </NeedImageWrapper>
        </NeedRowWrapper>
      </NeedContainer>
    </NeedWrapper>
  )
}

export default Need
