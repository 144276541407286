import React from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import topScrollIcon from '../../images/parent/scroll_top_icon.svg'

const TopScrollWrapper = styled.div`
  background-color: ${(props) => props.theme.colorWhite};
  padding-top: 40px;
  padding-bottom: 40px;
`

const TopScrollButtonWrapper = styled(Col)`
  text-align: center;
`

const TopScrollButton = styled(Link)`
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;
  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url(${topScrollIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .text {
    display: block;
    color: ${(props) => props.theme.colorGreenLight};
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 18px;
    font-weight: 700;
    text-transform: lowercase;
  }
`

const TopScroll = (props) => {
  const { className } = props
  return (
    <TopScrollWrapper className={className}>
      <Container>
        <Row>
          <TopScrollButtonWrapper xs={12}>
            <TopScrollButton to="top" smooth offset={-60}>
              <span className="icon"></span>
              <span className="text">powrót na{'\u00A0'}górę</span>
            </TopScrollButton>
          </TopScrollButtonWrapper>
        </Row>
      </Container>
    </TopScrollWrapper>
  )
}

export default TopScroll
